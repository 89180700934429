<script>
  import GlobalVue from '../../helper/Global.vue'
  import Gen from '../../helper/Gen'

  export default {
    extends: GlobalVue,
    data() {
      return {
        input: {}
      }
    },
    computed: {
      mrValidation() {
        return this.$root.config.mrValidation.account_setting
      },
    },
    mounted() {
      this.$emit("ready", this);
      $(document).ready(function () {
        setTimeout(() => {
          $("label[error]").hide()
        $("form").submit(function () {
          $("form label[error]").show();
        });
        }, 300);
      })
    },
    methods: {
      open(params) {
        global.Modal = this
        $(this.$el).modal()
        this.getUserData()
      },
      onSubmit(e) {
        if (e && e.btnLoading()) return;
        Gen.apirest("/submit-account-setting", this.input, (err, resp) => {
          if (e) e.btnUnloading()
          if (err && err.resp.description) return swal(err.resp.message, err.resp.description, "warning")
          if (err) return swal(err.resp.message, '', "warning")
          this.refreshVariableFo()
          this.getUserData()
          this.onEdit = 'N'
          $(this.$el).modal("hide")
          swal(resp.message, '', 'success')
        })
      },
      getUserData() {
        this.$root.topProgress.done()
        Gen.apirest("/get-user-data", {}, (err, resp) => {
          if (err) console.log(err)
          this.input = resp.data
        })
      },
      // getPostalArea(val) {
      // 	if (!val) return
      // 	let address = val.split(",")
      // 	Gen.apirest("/select-postal?province=" + address[0] + "&city=" + address[1] + "&district=" + address[2] +
      // 		"&sub_district=" + address[3], {}, (err, resp) => {
      // 			if (err) console.log(err)
      // 			if (!resp) return
      // 			this.$set(this.input, "postal_code", (resp[0] || {}).postal_code)
      // 		})
      // },
      getPostalArea(val) {
        let sub_district = val.val
        console.log(sub_district)
        Gen.apirest("/select-postal?province=" + this.input.province + "&city=" + this.input.city + "&district=" + this
          .input.district +
          "&sub_district=" + sub_district, {}, (err, resp) => {
            if (err) console.log(err)
            if (!resp) return
            this.$set(this.input, "postal_code", (resp[0] || {}).postal_code)
          })
      },
    },
    watch: {}
  }
</script>

<template>
  <div class="modal fade" id="ModalAccountSetting">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">{{web.lbl_account_setting}}</h4>
          <button class="close" data-dismiss="modal" aria-label="close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <VForm @resp="onSubmit">
          <div class="modal-body">
            <VInfo ref="info"></VInfo>
            <div class="form-row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="">{{web.lbl_name}}</label>
                  <input type="text" name="fullname" v-model="input.fullname" class="form-control"
                    onkeydown="return fullNameKey(event)" :placeholder="web.lbl_name" v-bind="validation('fullname')">
                  <LabelError name="fullname"></LabelError>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="">{{web.lbl_email}}</label>
                  <input type="email" name="email" v-model="input.email" onkeydown="return emailKey(event)"
                    class="form-control" v-bind="validation('email')" placeholder="example@mail.com">
                  <LabelError name="email"></LabelError>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="">{{web.lbl_phone}}</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">+62</span>
                    </div>
                    <!-- <input type="text" @change="regexPhone(input.ac_contacts)" v-model="input.ac_contacts" name=""
                      id="" class="form-control"> -->
                    <InputNumber type="PHONE" v-model="input.phone" class="form-control" placeholder="08xx-xxxx-xxxx">
                    </InputNumber>
                  </div>
                </div>
              </div>
            </div>
            <h4 class="mb-3">{{web.lbl_address}}</h4>
            <div class="form-group">
                  <label for="">{{web.lbl_address}}</label>
                  <textarea v-model="input.address" name="address" cols="30" rows="4" :placeholder="web.lbl_address"
                    v-bind="validation('address')" class="form-control"></textarea>
                  <LabelError name="address"></LabelError>
                </div>
            <div class="form-row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="">{{web.lbl_province}}</label>
                  <select2 name="province" v-model="input.province" :url="'/select-province'"
                    :object="['province','province']">
                    <option value="">-- {{web.lbl_select_province}} --</option>
                  </select2>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="">{{web.lbl_city}}</label>
                  <select2 name="city" v-model="input.city" :url="'/select-city?province='+input.province"
                    :object="['city','city']">
                    <option value="">-- {{web.lbl_select_city}} --</option>
                  </select2>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="">{{web.lbl_district}}</label>
                  <select2 name="district" v-model="input.district"
                    :url="'/select-district?province='+input.province+'&city='+input.city"
                    :object="['district','district']">
                    <option value="">-- {{web.lbl_select_district}} --</option>
                  </select2>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="">{{web.lbl_sub_district}}</label>
                  <select2 name="district" v-model="input.sub_district"
                    :url="'/select-sub-district?province='+input.province+'&city='+input.city+'&district='+input.district"
                    :object="['sub_district','sub_district']" @change="getPostalArea($event)">
                    <option value="">-- {{web.lbl_select_subdistrict}} --</option>
                  </select2>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="">{{web.lbl_postal_area}}</label>
                  <div class="row">
                    <div class="col-md-6">
                      <input name="text" v-model="input.postal_code" :placeholder="web.lbl_postal_area" readonly
                        class="form-control">
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <h4>{{web.lbl_change_password}}</h4>
            <div class="form-row">
              <div class="col-md-8">
                <div class="form-group view_password">
                  <label for="">{{web.lbl_new_password}}</label>
                  <input type="password" name="password" id="password" v-model="input.password"
                    v-bind="validation('password')" :placeholder="web.lbl_new_password" class="form-control">
                  <div class="togglepass">
                    <i class="btn_view icon-eye-close"></i>
                  </div>
                  <LabelError name="password"></LabelError>
                </div>
              </div>
              <div class="col-md-8">
                <div class="form-group view_password">
                  <label for="">{{web.lbl_confirm_password}}</label>
                  <input type="password" name="confirm_password" :placeholder="web.lbl_confirm_password"
                    v-model="input.confirm_password" v-bind="validation('confirm_password')" class="form-control">
                  <div class="togglepass">
                    <i class="btn_view icon-eye-close"></i>
                  </div>
                  <LabelError name="confirm_password"></LabelError>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="button btn-loading button-red">Submit</button>
          </div>
        </VForm>
      </div>
    </div>
  </div>
</template>